<template>
	<div class="page">
		<div class="nav">
			<div class="navTit">系统使用指南</div>
			<div class="navBox">
				<div class="nav1" v-for="(n1, i1) in navList" :key="i1">
					<div class="nav1Tit not-select" :class="[curNav1Index === i1 ? 'active' : '']" @click="changeNav1(n1, i1)">
						<img class="heisanjiao" :class="[nav1IndexOpenedArr.indexOf(i1) > -1 ? 'opened' : '']"
							v-if="n1.videoInfos.length > 0" src="../../assets/img/layout/chanpin.png" />
						<div class="heisanjiao" v-else></div>
						{{ n1.title }}
					</div>
					<div class="nav1Con" v-show="nav1IndexOpenedArr.indexOf(i1) > -1">
						<div class="nav2" v-for="(n2, i2) in n1.videoInfos" :key="i2">
							<div class="nav2Tit not-select" :class="[curNav1Index === i1 && curNav2Index === i2 ? 'active' : '']"
								@click="changeNav2(n1, i1, n2, i2)">
								{{ n2.title }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="contentTit">
				{{ navList[curNav1Index].title }}<span v-if="navList[curNav1Index].videoInfos.length > 0">-{{
					navList[curNav1Index].videoInfos[curNav2Index].title }}</span>
			</div>
			<div class="videoBox">
				<video style="height: 100%;" controls="controls"
					:src="navList[curNav1Index].videoInfos.length === 0 ? navList[curNav1Index].video : navList[curNav1Index].videoInfos[curNav2Index].video"></video>
			</div>
			<div class="pageBox">
				<div v-if="!(curNav1Index === 0 && curNav2Index === 0)">
					上一篇：
					<span class="pageText not-select" @click="prev">{{ prevText() }}</span>
				</div>
				<div v-else></div>
				<div
					v-if="!(curNav1Index === navList.length - 1 && curNav2Index === navList[curNav1Index].videoInfos.length - 1)">
					下一篇：
					<span class="pageText not-select" @click="next">{{ nextText() }}</span>
				</div>
				<div v-else></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			navList: [],
			nav1IndexOpenedArr: [0],
			curNav1Index: 0,
			curNav2Index: 0,

		}
	},
	mounted() {
		this.getVideoList()
	},
	methods: {
		// 获取
		getVideoList() {
			let loading = this.$loading()
			this.$http.get("/log/learn/Video/List").then(res => {
				loading.close()
				if (res.code === 0) {
					this.navList = res.data
				
					
				}
			})
		},
		changeNav1(n1, i1) {
			if (i1 === this.curNav1Index) {
				console.log(n1, i1)
				let index = this.nav1IndexOpenedArr.indexOf(i1)
				if (index === -1) {
					this.nav1IndexOpenedArr.push(i1)
				} else {
					this.nav1IndexOpenedArr.splice(index, 1)
				}
			} else {
				let index = this.nav1IndexOpenedArr.indexOf(i1)
				if (index === -1) {
					this.nav1IndexOpenedArr.push(i1)
				} else {
					this.nav1IndexOpenedArr.splice(index, 1)
				}
				if (n1.videoInfos.length === 0) {
					this.curNav1Index = i1;
					this.curNav2Index = 0;
				}
			}

		},
		changeNav2(n1, i1, n2, i2) {
			if (i1 === this.curNav1Index && i2 === this.curNav2Index) {
				console.log(n1, i1, n2, i2)
			} else {
				this.curNav1Index = i1;
				this.curNav2Index = i2;
			}
		},
		prev() {
			if (this.curNav2Index === 0) {
				this.curNav1Index = this.curNav1Index - 1;
				if (this.navList[this.curNav1Index].videoInfos.length === 0) {
					this.curNav2Index = 0
				} else {
					this.curNav2Index = this.navList[this.curNav1Index].videoInfos.length - 1
				}
			} else {
				// this.curNav1Index = this.curNav1Index
				this.curNav2Index = this.curNav2Index - 1
			}
		},
		next() {
			if (this.navList[this.curNav1Index].videoInfos.length === 0) { // 当前nav无二级
				this.curNav1Index = this.curNav1Index + 1
				this.curNav2Index = 0
			} else {
				if (this.curNav2Index === this.navList[this.curNav1Index].videoInfos.length - 1) {
					this.curNav1Index = this.curNav1Index + 1
					this.curNav2Index = 0
				} else {
					// this.curNav1Index = this.curNav1Index
					this.curNav2Index = this.curNav2Index + 1
				}
			}
		},
		prevText() {
			if (this.curNav2Index === 0) {
				let a = this.curNav1Index - 1;
				let b = this.navList[a].videoInfos.length - 1
				if (b === -1) {
					return this.navList[a].title
				} else {
					return this.navList[a].title + "-" + this.navList[a].videoInfos[b].title
				}
			} else {
				return this.navList[this.curNav1Index].title + "-" + this.navList[this.curNav1Index].videoInfos[this.curNav2Index - 1].title
			}
		},
		nextText() {
			if (this.navList[this.curNav1Index].videoInfos.length === 0) { // 当前nav无二级
				let a = this.curNav1Index + 1
				if (this.navList[a].videoInfos.length === 0) {
					return this.navList[a].title
				} else {
					return this.navList[a].title + "-" + this.navList[a].videoInfos[0].title
				}
			} else {
				if (this.curNav2Index === this.navList[this.curNav1Index].videoInfos.length - 1) {
					let a = this.curNav1Index + 1;
					if (this.navList[a].videoInfos.length === 0) {
						return this.navList[a].title
					} else {
						return this.navList[a].title + "-" + this.navList[a].videoInfos[0].title
					}
				} else {
					let a = this.curNav1Index;
					let b = this.curNav2Index + 1
					if (this.navList[a].videoInfos.length === 0) {
						return this.navList[a].title
					} else {
						return this.navList[a].title + "-" + this.navList[a].videoInfos[b].title
					}
				}
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: calc(100% - 20px);
	display: flex;
	background-color: #fff;
	border-radius: 20px;

	.nav {
		width: 260px;
		height: 100%;
		border-radius: 20px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .31);
		background-color: #fff;

		.navTit {
			height: 84px;
			line-height: 84px;
			padding: 0 32px;
			font-size: 20px;
			color: #333;
			font-weight: 600;
		}

		.navBox {
			margin: 0 7px;
			height: calc(100% - 84px);
			overflow: auto;

			&::-webkit-scrollbar {
				width: 4px;
				// height: 4px;
			}

			.nav1 {
				.nav1Tit {
					font-size: 16px;
					color: #333;
					height: 58px;
					line-height: 56px;
					border-top: 1px solid #efefef;
					border-bottom: 1px solid #efefef;
					padding: 0 20px 0 25px;
					cursor: pointer;

					// &:hover{
					// 	color:$mainColor;
					// }
					&.active {
						color: $mainColor;
					}

					.heisanjiao {
						width: 20px;
						height: 20px;
						vertical-align: middle;
						display: inline-block;
						transform: rotate(-90deg);

						&.opened {
							transform: rotate(0);
						}
					}
				}

				.nav1Con {
					.nav2 {
						padding: 0 0px 0 53px;

						.nav2Tit {
							font-size: 14px;
							color: #666;
							padding: 7px 0;
							cursor: pointer;

							// &:hover{
							// 	color:$mainColor;
							// }
							&.active {
								color: $mainColor;
							}
						}

						&:first-child {
							padding-top: 7px;
						}

						&:last-child {
							padding-bottom: 7px;
						}
					}
				}
			}
		}
	}

	.content {
		width: calc(100% - 260px);
		padding: 80px 32px 20px;
		font-size: 16px;
		color: #333;
		box-sizing: border-box;

		.videoBox {
			height: 500px;
			background-color: rgba(0, 0, 0, .31);
			margin: 30px 0;
			text-align: center;
		}

		.pageBox {
			font-size: 16px;
			color: #666;
			display: flex;
			justify-content: space-between;

			.pageText {
				color: $mainColor;
				cursor: pointer;
			}
		}
	}
}

.not-select {
	-moz-user-select: none;
	/*火狐*/
	-webkit-user-select: none;
	/*webkit浏览器*/
	-ms-user-select: none;
	/*IE10*/
	-khtml-user-select: none;
	/*早期浏览器*/
	user-select: none;
}
</style>
